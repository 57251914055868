import React, { useEffect, useState } from 'react'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { convUnixDate, md5hash, sendDataToServer } from '../utils/functions';
import { Alert, AlertTitle, Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Grid } from '@mui/material';
import Cookies from 'js-cookie'
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


export default function ConfigPage(props) {
    const [conff, setConff] = useState([]);
    const [cntsm, setCntsm] = useState(0)
    const [ecmkey, setEcmkey] = useState("")
    const [ecmerr, setEcmerr] = useState(false)
    const [period, setPeriod] = useState("")
    const [lim, setLim] = useState("50")
    const [uptime, setUptime] = useState("")
    const [dbsize, setDbsize] = useState("")

    

    useEffect(() => {
        getUptime()
        sendDataToServer({ 'op': "getConf" })
            .then(r => {
                setCntsm(parseInt(r[r.length-1])) // последний элемент
                for(let i=0; i<r.length; i++){
                    if(r[i]["key"] === "ecm_key"){
                        setEcmkey(r[i]["value"])
                    }else if(r[i]["key"] === "lim"){
                        setLim(r[i]["value"])
                    }else if(r[i]["key"] === "period"){
                        setPeriod(r[i]["value"])
                    }
                }

                delete r[r.length-1]

                setConff(r);
            });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(getUptime, 1000);
        return () => clearInterval(intervalId);
    }, [])

    function getUptime(){
        sendDataToServer({ 'op': "getUptime"})
        .then(r => {
            setUptime(r.status)
            setDbsize(r.dbsize)
        })
    }



    function handleSubmit(e){
        e.preventDefault();
        sendDataToServer({
            'op' : "setConf",
            'formData' : conff
        }).then(() => {
            //if(r.status === "OK"){
                Cookies.remove('user')
                Cookies.remove('rank')
                Cookies.remove('hash')
                window.location.href="/"
            //}else{
            //   alert(r.status)
            //} 
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
    
        setConff(prevConff => {
            return prevConff.map(confItem => {
                if (confItem.key === name) {
                    if(confItem.key === "ecm_key" && value.length <= 64){
                        setEcmkey(value)
                        //handleChangeEcm()
                        //console.log(ecmkey, value)
                        setEcmerr(false)
                    }
                    return { ...confItem, value: value };
                } else {
                    return confItem;
                }
            });
        });
    };



    function handleChangeEcm(){
        setEcmkey(md5hash(ecmkey)+md5hash(md5hash(ecmkey)))
        setEcmerr(true)
    }


    function selectPeriod(v){
        const per = [
            '5 minutes (for tests)',      // 0
            '1 hour',                     // 1
            '1 day',                      // 2
            '1 week',                     // 3
            '1 month (default)',          // 4
            '3 monthes',                  // 5
            '6 monthes',                  // 6
            '1 year',                     // 7
            '3 years',                    // 8
            '5 years (not recommended)'   // 9
        ]

        return (
            <FormControl fullWidth>
                <InputLabel id="select-period-label">{v.key}</InputLabel>
                <Select
                    labelId="select-period-label"
                    id="select-period-label"
                    label={"period"}
                    name={"period"}
                    value={period}
                    onChange={
                        (e) =>{
                            setPeriod(e.target.value)
                            var _id = 0
                            for (var i=0; i<conff.length; i++){
                                if (conff[i].key === v.key) {
                                    _id = i
                                    break
                                }
                            }
                            conff[_id]["value"] = e.target.value
                            setConff(conff)
                        }
                    }
                >
                    {
                        per.map((v, k) => (
                            <MenuItem key={"k"+k} value={k+""}>[{k}] {v}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )
    }



    function selectLim(v){
        return (
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{v.key}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={v.key}
                    name={v.key}
                    value={lim}
                    onChange={(e) => {
                        setLim(e.target.value)
                        var _id = 0
                        for (var i=0; i<conff.length; i++){
                            if (conff[i].key === v.key) {
                                _id = i
                                break
                            }
                        }
                        conff[_id]["value"] = e.target.value
                        setConff(conff)
                    }}
                >
                   <MenuItem value="10">10</MenuItem>
                   <MenuItem value="30">30</MenuItem>
                   <MenuItem value="50">50</MenuItem>
                   <MenuItem value="100">100</MenuItem>
                </Select>
            </FormControl>
        )
    }










    // ██████╗ ███████╗████████╗██╗   ██╗██████╗ ███╗   ██╗
    // ██╔══██╗██╔════╝╚══██╔══╝██║   ██║██╔══██╗████╗  ██║
    // ██████╔╝█████╗     ██║   ██║   ██║██████╔╝██╔██╗ ██║
    // ██╔══██╗██╔══╝     ██║   ██║   ██║██╔══██╗██║╚██╗██║
    // ██║  ██║███████╗   ██║   ╚██████╔╝██║  ██║██║ ╚████║
    // ╚═╝  ╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝
                                                        


    return (
        <>
            <Grid container>
                <Grid item xs={7}>
                    <h2>
                        <SettingsSuggestIcon style={{ marginRight: "10px" }} />
                        Configuration server
                    </h2>
                </Grid>
                <Grid item xs={5}>
                    <Grid container style={{marginTop:"20px"}}>
                        <Grid item xs={4} style={{marginTop:"15px", color:"gray", font:"11px/1.1em Verdana"}}>
                            {dbsize}
                        </Grid>
                        <Grid item xs={5} style={{marginTop:"10px"}}>
                            Uptime: <b>{uptime}</b>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="outlined" color="error" onClick={
                                () => {
                                    sendDataToServer({'op':"restart"})
                                    window.location.href=""
                                }
                            }><RestartAltIcon /> RESTART</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <hr />

            <Alert severity="error" style={{marginBottom: "30px"}}>
                <AlertTitle>Sensitive parameters!</AlertTitle>
                Be careful when changing settings. Most of them will enter into force after the server restart.
            </Alert>
            <form onSubmit={handleSubmit} >
            <table width='100%'>
                <tbody>
                    {
                        conff.map((v, index) => (
                             <tr key={"kk+" + index}>
                                <td
                                    align="right"
                                    valign="top"
                                    style={{paddingTop: "30px"}}
                                    width="30%">
                                        {(v.key==="AID" && v.descr!=="") ?  (v.descr==="+" ? <CheckCircleIcon style={{color:"green"}} /> : <ErrorIcon style={{color:"red"}} />) : v.descr}
                                        {(v.key==="ecm_key" && cntsm <= 0) && <CachedIcon onClick={() => handleChangeEcm()} className="btnReloadecm" />}
                                        {(v.key==="ecm_key" && cntsm <= 0) && <div style={{font:"11px/1em Verdana", paddingRight:"40px"}}>[<a href="/converter.exe" target="_blank" rel="noreferrer">converter.exe</a>]</div>}
                                </td>
                             <td style={{padding:"10px 0 5px 10px"}}>
                             <Box
                                sx={{
                                    width: 500,
                                    maxWidth: '100%',
                                }}
                                >{
                                    v.key.indexOf("lim") === 0 ?
                                        selectLim(v) :
                                    v.key.indexOf("period") === 0 ?
                                        selectPeriod(v)
                                    :
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        name={v.key}
                                        label={v.key}
                                        variant="outlined"
                                        value={v.key==="ecm_key" ? ecmkey : conff[index].value} 
                                        disabled={v.key === "UID" || (v.key === "ecm_key" && cntsm > 0) || v.key === "ident2"}
                                        onBlur={handleChange}
                                        onChange={handleChange}
                                        error={v.key==="ecm_key" ? ecmerr : false} 
                                    />
                                }
                                    <FormHelperText>{
                                        (v.value !== v.last && v.last !== "") && "Last value: " + v.last + ". Change " + convUnixDate(Number(v.e_time)>0 ? v.e_time : 0)
                                    }</FormHelperText>
                                </Box>
                             </td>
                             </tr>
                        ))
                    }
                    <tr><td></td><td style={{padding:"30px 10px"}}>
                        <Button variant="contained" type="submit" color={cntsm===-1 ? "error" : "primary"}>{
                            (cntsm===-1 ? "Save & update ECM/EMM" : "Save & restart")
                        }</Button>
                    </td></tr>
                </tbody>
            </table>
            </form>
        </>
    );
}