import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import SmartcardsPage from '../pages/SmartcardsPage'
import PackagesPage from '../pages/PackagesPage'
import EcmgPage from '../pages/EcmgPage'
import EmmgPage from '../pages/EmmgPage'
import LogsPage from '../pages/LogsPage'
import ConfigPage from '../pages/ConfigPage'
import SmsPage from '../pages/SmsPage'
import LogoutPage from '../pages/LogoutPage'
import NotFoundPage from '../pages/NotFoundPage'
import { sendDataToServer } from './functions';



export default function RoutesUser(props){

    const [paths, setPaths] = useState([])

    useEffect(function(){
        sendDataToServer({ 'op': "getPaths" })
            .then(r => {
                setPaths(r["paths"].split(","))
                //console.log(r)
            })
    }, [])

    return(
        <Routes>
            <Route path='/' element={<SmartcardsPage />} />
            <Route path='/smartcards' element={<SmartcardsPage />} />
            {
                paths.map(v => {
                    switch (v) {
                        case "packages":
                            return <Route key="packages" path='/packages' element={<PackagesPage />} />;
                        case "ecmg":
                            return <Route key="ecmg" path='/ecmg' element={<EcmgPage />} />;
                        case "emmg":
                            return <Route key="emmg" path='/emmg' element={<EmmgPage />} />;
                        case "sms":
                            return <Route key="sms" path='/sms' element={<SmsPage />} />;
                        case "logs":
                            return <Route key="logs" path='/logs' element={<LogsPage />} />;
                        default:
                            return null;
                    }
                })
            }
            <Route path='/config' element={<ConfigPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path='/*' element={<NotFoundPage />} />
        </Routes>
    );
}
