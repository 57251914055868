import React, { useEffect, useState } from "react";
import StorageIcon from '@mui/icons-material/Storage';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { convUnixDate, sendDataToServer } from "../utils/functions";
import Cookies from "js-cookie";
import RadarIcon from '@mui/icons-material/Radar';
import Radar from "./Radar";

export default function Ecmg({data}){

    const [enable, setEnable] = useState(data.enable==="1" ? true : false)
    const [del, setDel] = useState(false)
    const [show, setShow] = useState("server")
    const [start, setStart] = useState(0)
    const [connect, setConnect] = useState(0)
    const [key, setKey] = useState("")
    const [peers, setPeers] = useState("")
    const [ddescr, setDdescr] = useState("")
    const [dport, setDport] = useState("")
    const [blocked, setBlocked] = useState(false)
    const [mode, setMode] = useState(data.mode);
    const [gift, setGift] = useState(data.gift==="1"?true:false)
    const [radar, setRadar] = useState(false)


    useEffect(() => {
        const updateEverySecond = () => {
            sendDataToServer({'op':"checkECMconnect", 'id': data.id}).then(r => {  
                if (r[0]) {
                    setStart(r[0].start)
                    setConnect(parseInt(r[0].connect))
                    setPeers(r[0].peers)
                    setKey(r[0].key)
                }
            })
        }
        updateEverySecond()
        const intervalId = setInterval(updateEverySecond, 1500);
        return () => clearInterval(intervalId);
    // eslint-disable-next-line
    }, [])




    function handleEnable(e, id){
        var chk = e.target.checked
        sendDataToServer({'op':"enableECMG", 'id': id, 'enable': (chk ? "1" : "0")}).then(r => {
            if (r.status === "OK"){
                setEnable(chk)
                if(!chk){
                    setBlocked(true)
                    setTimeout(function(){
                        setBlocked(false)
                    }, 8000)
                }
            }
        })
    }

    function handleConfirm(id){
        sendDataToServer({'op':"deleteECMG", 'id': id}).then(r => {
                setDel(false)  
                if (r.status === "OK"){
                    setShow("server hide")
                }
            }
        )
        
    }


    function handleDelete(id){
        setDel(true)
    }

    function connTime(ttt){
        if(ttt==="" || ttt==="0" || ttt<10){
            return ""
        }else{
            return convUnixDate(ttt)
        }
    }


    function handleEdit(e, k, id){
        var v = e.target.value
        sendDataToServer({'op': "editECMG", 'row': k, 'value': v, 'id': id}).then(r => {
            if(r["status"]==="OK"){
                if (k==="descr")setDdescr(v)
                if (k==="port")setDport(v)
            }
        })
    }


    
    function handleMode(e, id){
        var v = e.target.value
        sendDataToServer({'op': "modeEcmg", 'mode': v, 'id': id}).then(r => {
            if(r["status"]==="OK"){
                setMode(v)
                //console.log(v)
            }
        })
    }



    function changeGift(e, id){
        var v = e.target.checked
        // console.log(v)
        sendDataToServer({'op': "giftEcmg", 'gift': (v ? "1" : "0"), 'id': id}).then(r => {
            if(r["status"]==="OK"){
                setGift(v)
            }
        })
    }





    return (
        <div className={show} style={{animation: enable ? "moveStripes 20s linear infinite" : "none" }} >
            <Grid container>
                <Grid item style={{textAlign:"center"}} xs={3}>
                    <StorageIcon style={{fontSize:"70px"}} />
                    <div>{"ECMG #" + data.id}</div>
                </Grid>
                <Grid item xs={8}>
                    <div><TextField
                        disabled={enable}
                        className="text-serverg"
                        variant="filled"
                        label="Description"
                        id={"text-descr-ecm"+data.id}
                        size="small"
                        style={{marginRight:"5px"}}
                        value={ddescr ? ddescr : data.descr}
                        onChange={e => handleEdit(e, "descr", data.id)}
                    />
                    <TextField
                        disabled={enable}
                        className="text-serverg"
                        variant="filled"
                        label="Port"
                        id={"text-dport-ecm"+data.id}
                        size="small"
                        value={dport ? dport : data.port}
                        onChange={e => handleEdit(e, "port", data.id)}
                    /></div>
                    <table width="100%"><tbody>
                        <tr><td>
                    <div className="server-connect">
                        <div><b>Start</b>: <span>{connTime(start)}</span></div>
                        <div><b>Connect</b>: <span>{connTime(connect)}</span></div>
                        <div><b>Peers</b>: <span>{(parseInt(peers) !== 0 && peers)}</span></div>
                        {
                            key !== "" && <div><b>Custom key</b>: <Tooltip title="Delete custom key"><span className="tipa-a" onClick={() => {
                                sendDataToServer({'op': "deleteCustomKey", 'id': data.id})
                            }}>{key}</span></Tooltip></div>
                        }
                    </div></td>

                    <td align="right"><FormControlLabel control={<Checkbox disabled={enable} checked={gift} onChange={e => changeGift(e, data.id)} />} label="Gift" /></td>


                    <td align="right" style={{paddingRight:"10px", width:"100px"}}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            labelId="demo-select-mode"
                            value={mode}
                            onChange={(e) => handleMode(e, data.id)}
                            disabled={enable}
                            id={"select-mode-"+data.id}
                        >
                            <MenuItem value="1" id={"select-mode1-"+data.id}>[ CON ]</MenuItem>
                            <MenuItem value="2" id={"select-mode2-"+data.id}>[ IRD ]</MenuItem>
                        </Select>
                        </FormControl>
                    </td></tr>



                    </tbody></table>
                </Grid>
                <Grid item xs={1}>
                    <div style={{marginTop:"10px"}}>
                        <Switch disabled={blocked === true} aria-label="on-off" id={"on-off"+data.id} checked={enable} onChange={e => 
                            handleEnable(e, data.id)
                         } />
                    </div>
                    <div style={{marginTop:"10px"}}>
                        {(!blocked && !enable && Cookies.get("user") === "admin") ?
                            <Button onClick={handleDelete}><DeleteOutlineIcon /></Button> : 
                                (Cookies.get("user") === "admin" && !blocked && parseInt(peers) !== 0) &&
                                    <Tooltip title="Radar">
                                        <Button onClick={() => {
                                            sendDataToServer({
                                                op: "radar_send",
                                                ecmid: data.id,
                                                mode: data.mode
                                            }).then(function(r){
                                                setRadar(true)
                                            })
                                        }}><RadarIcon /></Button>
                                    </Tooltip>
                        }
                    </div>
                </Grid>
            </Grid>

            <Dialog open={del} onClose={setDel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent><DialogContentText id="alert-dialog-description">
                    Are you sure remove ECMG #{data.id}?
                </DialogContentText></DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirm(data.id)}>Yes</Button>
                    <Button autoFocus onClick={() => setDel(false)}>No</Button>
                </DialogActions>
            </Dialog>

            <Radar open={radar} hideRadar={(e) => setRadar(e)} loader={true} mode={data.mode} />

            
        </div>
    )
}