import { Alert, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'


export default function AlertNothing(props){
    const [load, setLoad] = useState(true)

    useEffect(function(){
        setTimeout(function(){
            setLoad(false)
        }, 500)
    }, [])

    return (
        <>
            {load ? (
                <span className="loader-center"><CircularProgress /></span>
            ) : (
                <Alert severity="info" >
                    {props.children}
                </Alert>
            )}
        </>
    )
}