import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';

const FileUpload = () => {
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    fetch(process.env.REACT_APP_IMPORT, {
      method: 'POST',
      body: formData,
    })
    .then(async response => {
        if (response.ok) {
          const text = await response.text(); // Получаем текст из ответа
          if(text === "OK"){
            alert("File upload success!")
            window.location.href = ""
          }
        } else {
          throw new Error('Error upload file!');
        }
    })
    .catch(error => {
      console.error("Something error to upload file!", error)
    });
  };

  return (
    <>
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button
        onClick={handleFileUpload}
        style={{marginTop: "20px"}}
        variant="contained" color="success">
            <AddToHomeScreenIcon /> IMPORT
      </Button>
    </>
  )
}

export default FileUpload;
