import React, { useRef, useState } from 'react';
import Clipboard from 'clipboard';

const CopyToClipboard = (props) => {
  const [copied, setCopied] = useState(false);
  const textRef = useRef(null);

  const handleCopy = () => {
    const clipboard = new Clipboard(textRef.current);
    clipboard.on('success', () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 250);
    });
    clipboard.on('error', () => {
      console.error('Failed to copy.');
    });
    clipboard.onClick({ currentTarget: textRef.current });
  };

  return (
    <>
      {
        copied ? <span style={{ marginLeft: '5px', padding: "0 5px", color: "white", backgroundColor: 'green' }}>Copied!</span> :
            <span style={{cursor: "pointer", color: "#1976d2"}} ref={textRef} data-clipboard-text={props.children} onClick={handleCopy}>
            {props.children}</span>
      }
    </>
  );
};

export default CopyToClipboard;
