import React, { useEffect, useRef, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, Snackbar, Tooltip } from '@mui/material';
import { sendDataToServer } from '../utils/functions';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Cookies from 'js-cookie';
import CopyToClipboard from '../components/CopyToClipboard';
import AlertNothing from '../components/AlertNothing';


export default function PackagesPage(props){

    const [packag, setPackages] = useState([])
    const [err, setErr] = useState(false)
    const [dialog, setDialog] = useState(0)
    const [total, setTotal] = useState("00000000")
    const [pin, setPin] = useState({})
    const [ac, setAc] = useState({})

    const totRef = useRef({})


    useEffect(function(){
        sendDataToServer({'op': "getPackages"}).then(r => {
            setPackages(r)
            const newPin = {}; // Создать новый объект для состояния PIN
            const newAc = {}; // Создать новый объект для состояния PIN
            for(var i=0; i<r.length; i++){
                newPin[r[i].id] = r[i].pin === "1" ? true : false;
                newAc[r[i].id] = idToAc(r[i].id, r[i].pin)
            }
            setPin(newPin); // Установить новый объект состояния PIN
            setAc(newAc);
        })
    }, [])




    function handlePinChange(id, checked){
        const newPin = { ...pin, [id]: checked }; // Создать новый объект с обновленным состоянием PIN
        const newAc = { ...ac, [id]: idToAc(id, checked ? "1" : "0") }; // Создать новый объект с обновленным состоянием PIN
        setPin(newPin); // Установить новый объект состояния PIN
        setAc(newAc);
        sendDataToServer({'op':"setPin", 'id': id, 'pin': checked ? "1" : "0"}); // Отправить изменения на сервер
        // let chk = totRef.current[id].querySelector('input[type="checkbox"]')
        // if (chk.checked && checked){
        //     chk.click()
        // }
        // if (checked){
        //     totRef.current[id].style.display = "none"
        // }else if (!checked){
        //     totRef.current[id].style.display = ""
        // }
    }



    function handleAdd(){
        sendDataToServer({'op': "addPackage"}).then(r => {
            setPackages(prevPackages => {
                var newPackages = [];
                if (prevPackages.length < 31){
                    newPackages = [...prevPackages, r[0]];
                    handlePinChange(r[0].id, r[0].pin==="1"?true:false )
                }else{
                    newPackages = prevPackages
                    setErr(true)
                }
                return newPackages;
            });
        })
    }

    function  handleRemove(id){
        sendDataToServer({op: "removePackage", id: id}).then(r => {
            setPackages(r);
            setDialog(0)
        })
    }


    function idToAc(id, pin){
        let out = Math.pow(2, parseInt(id)-1)
        if (pin === "1" || pin === true){
            out += 0x80000000
        }
        out = out.toString(16);
        out = out.padStart(8 * Math.ceil(out.length / 8), '0');
        return out;
    }



    function handleInput(v, id){
        sendDataToServer({op: "editDescr", id: id, descr: v}).then(r => {
            if (r["status"] !== "OK") alert("Error send data to server!")
        })
    }

    function countClients(id){
        sendDataToServer({op: "countClients", id: id}).then(r => {
            setPackages(r);
        })
    }


    

    return( 
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2><SubscriptionsIcon style={{ marginRight: "10px" }} />
                Packages</h2>

                {Cookies.get("user") === "admin" ? <span>
                    <Button onClick={handleAdd} style={{marginTop: "20px"}} variant="contained"><AddCircleOutlineIcon /> Add package</Button>
                </span> : ""}
            </div>
            <hr />
            <center>
            <table width="70%" className="table_style">
                <thead><tr>
                    <td align="center">#</td>
                    <td>Description package</td>
                    <td align="center" style={{width:"30px"}}>PIN</td>
                    <td align="center">Clients</td>
                    <td align="center">Access criteria</td>
                    <td align="center" style={{width:"80px"}}><CopyToClipboard>{total}</CopyToClipboard></td>
                    <td></td>
                </tr></thead>
                <tbody>
            {
                packag.length===0 ? <tr><td colSpan={7} style={{padding: "20px"}}><AlertNothing>No packages. Please add a new package.</AlertNothing></td></tr> : 
                packag.map((v, k) => (

                    <tr key={k}>
                        <td align="center">{v["id"]}</td>
                        <td><Input key={"i"+k} id={"pckg"+v["id"]+k} disabled={Cookies.get('user')==="oper" ? true : false} onChange={(e) => handleInput(e.target.value, v["id"])} defaultValue={v["descr"]} style={{width:"100%", font: "16px/1.1em Consolas"}} /></td>
                        <td align="center">
                            {/*  pin code  */}
                            <Checkbox
                                size="small"
                                name={"pin"+v["id"]}
                                checked={ pin[v["id"]] }
                                onChange={(e)=>{
                                    handlePinChange(v["id"], e.target.checked)
                                }}
                            />
                        </td>
                        <td align="center"><Tooltip title="Click to count"  placement="right"><span onClick={()=>countClients(v["id"])} className="link-clients-package">{v["smartcards"]}</span></Tooltip></td>
                        <td align="center"><CopyToClipboard>{ac[v["id"]]}</CopyToClipboard></td>
                        <td align="center">
                            {/*  calculator  */}
                            <Checkbox
                                size="small"
                                ref={el => (totRef.current[v["id"]] = el)}
                                name={"chk"+v["id"]}
                                onChange={(e)=>{
                                    var t = 0
                                    if (e.target.checked) {
                                        t = parseInt(total, 16) + parseInt(idToAc(v["id"], v["pin"]), 16)
                                    }else{
                                        t = parseInt(total, 16) - parseInt(idToAc(v["id"], v["pin"]), 16)
                                    }
                                    t = t.toString(16).toUpperCase();
                                    while (t.length < 8) {
                                        t = '0' + t;
                                    }
                                    setTotal(t)
                                }}
                            />
                        </td>
                        <td align="center">
                            <Button disabled={parseInt(v["smartcards"]) === 0 && Cookies.get('user') === "admin" ? false : true} onClick={() => setDialog(v["id"])}><DeleteOutlineIcon /></Button>
                        </td>
                    </tr>

                ))
            }
                </tbody>
            </table>
            </center>


            <Snackbar
                open={err}
                autoHideDuration={6000}
                onClose={() => setErr(false)}
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
            >
                <Alert
                    onClose={() => setErr(false)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Only 31 packages support!
                </Alert>
            </Snackbar>


            <Dialog open={parseInt(dialog) > 0 ? true : false} onClose={setDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                            <DialogContent><DialogContentText id="alert-dialog-description">
                                Are you sure remove package?
                            </DialogContentText></DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => handleRemove(dialog)}>Yes</Button>
                                <Button onClick={() => setDialog(false)}>No</Button>
                            </DialogActions>
            </Dialog>

        </>
    )
}