import React, { useEffect, useState } from 'react'
import StorageIcon from '@mui/icons-material/Storage';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { sendDataToServer } from '../utils/functions';
import EMMG from '../components/EMMG';
import AlertNothing from '../components/AlertNothing';
import Cookies from 'js-cookie';

export default function EmmgPage(props){

    const [emmg, setEmmg] = useState([])
    const [upd, setUpd] = useState(0)

    useEffect(() => {
        sendDataToServer({ 'op': "getEmmg" })
            .then(r => {
                setEmmg(r);
            });
    }, [upd]);


    function handleAdd(){
        sendDataToServer({'op': "addEmmg"}).then(r => {
            setUpd(Math.random())
        })
    }



    return( 
        <>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h2>
                    <StorageIcon style={{ marginRight: "10px" }} />
                    EMM generators
                </h2>
                { Cookies.get("user") === "admin" ? 
                <span style={{marginTop:"20px"}}>
                    <Button variant="contained" onClick={handleAdd}><AddCircleOutlineIcon /> <span style={{paddingTop:"3px"}}>Add EMMg</span></Button>
                </span> : "" }
            </div>
            <hr />
            { 
                emmg.length===0 ? (
                    <AlertNothing>No generators. Please add new EMMg.</AlertNothing>
                ) : (
                    emmg.map(v => (
                        <EMMG key={"emmg"+v.id} data={v} />
                    ))
                )
            }
        </>
    )
}