import React, { useEffect, useState } from "react";
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Modal, TextField, Typography, Grid, Alert, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import AlertNothing from "../components/AlertNothing";
import { convUnixDate, sendDataToServer } from "../utils/functions";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BugReportIcon from '@mui/icons-material/BugReport';

const time = Math.floor(Date.now() / 1000);




export default function SmsPage(){
    const [sms, setSms] = useState([])
    const [edit, setEdit] = useState({})
    const [letters, setLetters] = useState(0)
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, finishDate] = dateRange;
    const [test, setTest] = useState([false, "2100000001"])
    


    useEffect(function(){
        const updateEverySecond = () => {
            sendDataToServer({'op':"getSms"}).then(r => {  
                if (r[0]) {
                    setSms(r)
                }
            })
        }
        updateEverySecond()
        const intervalId = setInterval(updateEverySecond, 1000);
        return () => clearInterval(intervalId);
    // eslint-disable-next-line
    }, [])




    function handleAdd(){
        sendDataToServer({op: "addSms"}).then(r => {
            setSms(r);
        })
    }

    function handleRemove(id, message){
        sendDataToServer({op: "removeSms", id, message}).then(r => {
            setSms(r);
        })
    }


    function handleEditMessage(e) {
        if (e.target.value.length<48 && !/[а-яА-ЯЁё]/.test(e.target.value)){
            setEdit(prevState => {
            const updatedEdit = { ...prevState, [e.target.name]: e.target.value };
            setLetters(updatedEdit["message"].length);
            return updatedEdit;
            });
        }
    }


    function handleEditMask(e){
        setEdit({ ...edit, [e.target.name]: e.target.value })
    }


    function handleSendForm(e){
        e.preventDefault();
        edit["s_time"] = startDate.getTime() / 1000
        edit["f_time"] = (finishDate.getTime() / 1000) + 86399 // до конца дня
        sendDataToServer({
                ...edit,
                op: "editSms"
        }).then(r => {
            if(r["status"]==="OK"){
                setEdit({})
            }
        })
    }     

    function handleClickMask(e){
        if (e.target.tagName === "LI"){
            setEdit({...edit, mask : e.target.getAttribute('rel')})
        }
    }



    function handleClickTest(){
        sendDataToServer({op:"testSms", sn:test[1]}).then(r => {
            if (r["status"] === "OK"){
                setTest([false, test[1]])
            }
        })
    }





    function handleChangeTest(e){
        const sn=e.target.value.replace(/[^\d]/g, "");
        if (sn.length<=10){
            setTest([true, sn])
        }
    }



// ██████╗ ███████╗████████╗██╗   ██╗██████╗ ███╗   ██╗
// ██╔══██╗██╔════╝╚══██╔══╝██║   ██║██╔══██╗████╗  ██║
// ██████╔╝█████╗     ██║   ██║   ██║██████╔╝██╔██╗ ██║
// ██╔══██╗██╔══╝     ██║   ██║   ██║██╔══██╗██║╚██╗██║
// ██║  ██║███████╗   ██║   ╚██████╔╝██║  ██║██║ ╚████║
// ╚═╝  ╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝
                                                    



    return (
        <>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h2>
                    <MarkEmailUnreadIcon style={{ marginRight: "10px" }} />
                    Short message service (SMS) <Tooltip title="Dont abuse this function as it causes slower EMM delivery"><ErrorOutlineIcon style={{position:"relative", bottom:"5px", fontSize:"18px", color:"#0d63be"}}/></Tooltip>
                </h2>
                <span>
                    <Button onClick={handleAdd} style={{marginTop: "20px"}} variant="contained"><AddCircleOutlineIcon /> Add SMS</Button>
                    <Button onClick={() => setTest([true, test[1]])} style={{margin: "20px 0 0 10px"}} variant="contained" color="warning"><BugReportIcon /> Test</Button>
                </span>
            </div>
            <hr />

            <table width="100%" className="table_style">
                <thead><tr>
                    <td align="center" width={"15px"}>#</td>
                    <td>Message (up to 47 letters)</td>
                    <td width={"200px"}>Start</td>
                    <td width={"200px"}>Finish</td>
                    <td>Mask</td>
                    <td width={"280px"}>Sent</td>
                    <td width={"25px"}></td>
                </tr></thead>
                <tbody>
                {

                    sms.length===0 ? <tr><td colSpan={7} style={{padding: "20px"}}><AlertNothing>No messages. Please add a new message.</AlertNothing></td></tr> : 
                    sms.map((v,k) => (
                        <tr key={k}>
                            <td align={"center"}>{k+1}</td>
                            <td><span className="tipa-a" onClick={() => {
                                setEdit(v)
                                setLetters(v.message.length)
                                setDateRange([new Date(parseInt(v["s_time"]) * 1000), new Date(parseInt(v["f_time"]) * 1000)])
                            }}>{v["message"]}</span></td>
                            <td className={v.s_time > time ? "future-sub" : v.f_time < time ? "last-sub" : ""}>{convUnixDate(v.s_time)}</td>
                            <td className={v.f_time < time ? "last-sub" : v.s_time > time ? "future-sub" : ""}>{convUnixDate(v.f_time)}</td>
                            <td>{v["mask"]}</td>
                            <td>{convUnixDate(v["q_time"]) + " ["+v["cnt"]+"] " + v["last_sn"]}</td>
                            <td align={"center"}>
                                <Button onClick={() => handleRemove(v["id"], v["message"])}><DeleteOutlineIcon /></Button>
                            </td>
                        </tr>
                        )
                    )


                }
                </tbody>
            </table>

























{/* ███████╗██████╗ ██╗████████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    ██╔════╝██╔══██╗██║╚══██╔══╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    █████╗  ██║  ██║██║   ██║       █████╗  ██║   ██║██████╔╝██╔████╔██║
    ██╔══╝  ██║  ██║██║   ██║       ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    ███████╗██████╔╝██║   ██║       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    ╚══════╝╚═════╝ ╚═╝   ╚═╝       ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
                                                                     */}


            <Modal
                open={"id" in edit? true : false}
                onClose={() => setEdit({})}
                aria-labelledby="edit-modal-title"
                aria-describedby="edit-modal-description"
            >
                <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '1px solid #000',
                            boxShadow: 10,
                            p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2"><BorderColorIcon /> Edit message</Typography>

                    <hr />
                    <form onSubmit={handleSendForm} >
                            <Typography style={{position:"relative", top:"20px"}}>Dispatch time period:</Typography>
                            <DatePicker
                                style={{marginTop: "0px"}}
                                selectsRange={true}
                                startDate={startDate}
                                finishDate={finishDate}
                                endDate={finishDate}
                                onChange={setDateRange}
                                dateFormat="dd.MM.yyyy"
                            />

                            <TextField
                                fullWidth   
                                value={edit.mask}
                                label="Mask"
                                name={"mask"}
                                onChange={handleEditMask}
                                style={{margin:"15px 0"}}
                            />

                            <Alert severity="info" icon={false} style={{marginBottom:"20px"}}>
                                <b>Select example condition:</b>
                                <div className="select-mask" onClick={handleClickMask}>
                                    <Tooltip title="For all programmed smartcards"><li rel="*">For all</li></Tooltip>
                                    <Tooltip title="For smartcards with an active subscription"><li rel="active">Only active</li></Tooltip>
                                    <Tooltip title="For one smartcard"><li rel="2100000001">Serial 2100000001</li></Tooltip>
                                    <Tooltip title="For smartcards in range"><li rel="2100000001-2100000099">Range 2100000001-2100000099</li></Tooltip>
                                    <Tooltip title="To smartcards specified by commas"><li rel="2100000001, 2100000101, 2100000105, 2100000150">Some 2100000001,2100000101..</li></Tooltip>
                                    <Tooltip title="To smartcards subscribed to package 1"><li rel="00000001">AC 00000001</li></Tooltip>
                                    <Tooltip title="SMS will be sent only to smartcards whose subscription ends in less than 7 days"><li rel="7days">7 days before ends</li></Tooltip>
                                    <Tooltip title="SMS will be sent only to smartcards whose subscription ends in less than 14 days"><li rel="14days">14 days before ends</li></Tooltip>
                                </div>
                            </Alert>

                            <TextField
                                fullWidth   
                                value={edit.message}
                                label="Message body"
                                name={"message"}
                                onChange={handleEditMessage}
                                style={{marginTop:"15px"}}
                            />
                            <Grid container>
                                <Grid item xs={6} style={{marginTop:"40px"}}>
                                    <span style={{color:"red"}}>{letters}/47</span>
                                </Grid>
                                <Grid  item xs={6} style={{marginTop:"30px", textAlign:"right"}}>
                                    <Button variant="contained" type="submit"><BorderColorIcon style={{fontSize:"14px", marginRight:"10px"}} /> Save</Button>
                                    <Button variant="outlined" style={{marginLeft:"5px"}} type="submit" onClick={() => setEdit({})}>Cancel</Button>
                                </Grid>
                            </Grid>
                    </form>
                </Box>
            </Modal>







 
{/*
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ████████╗███████╗███████╗████████╗
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ╚══██╔══╝██╔════╝██╔════╝╚══██╔══╝
██║  ██║██║███████║██║     ██║   ██║██║  ███╗       ██║   █████╗  ███████╗   ██║   
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║       ██║   ██╔══╝  ╚════██║   ██║   
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝       ██║   ███████╗███████║   ██║   
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝        ╚═╝   ╚══════╝╚══════╝   ╚═╝   
*/}
            <Dialog open={test[0]} onClose={() => setTest([false, test[1]])}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title"><BugReportIcon style={{position:"relative", top:"5px"}} /> {"Test SMS"}</DialogTitle>
                <DialogContent>
                    <Alert color="info">
                        This function allows to check whether message are reaching the CAM-module.
                    </Alert>    
                    <DialogContentText style={{marginTop:"20px"}} id="alert-dialog-description">
                        
                    </DialogContentText>
                    <center>
                        <TextField
                            id="standard-basic"
                            label="Serial No"
                            variant="standard"
                            value={test[1]}
                            onKeyDown={(e)=>{
                                if(e.key ==='Enter') handleClickTest()
                            }}
                            onChange={handleChangeTest}
                        /></center>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickTest}>Send</Button>
                    <Button onClick={() => setTest([false, test[1]])}>Cancel</Button>
                </DialogActions>
            </Dialog>           


        </>
    )
}