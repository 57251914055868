import React, { useEffect, useState } from "react"
import { Alert, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Typography } from "@mui/material"
import RadarIcon from '@mui/icons-material/Radar';
import { sendDataToServer } from "../utils/functions";
import CopyToClipboard from "./CopyToClipboard";



export default function Radar({open, hideRadar, mode}){

    const [preloader, setPreloader] = useState(true)
    const [cw, setCw] = useState("")
    const [serial, setSerial] = useState("not found")
    const [strict, setStrict] = useState(true)



    useEffect(() => {
        const timer = setTimeout(() => {
            setPreloader(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [preloader]);

    return(
        <Dialog open={open} onClose={()=>{}}
                aria-labelledby="alert-radar-title"
                aria-describedby="alert-radar-description"
        >
            <DialogTitle id="alert-radar-title"><RadarIcon /><span style={{position:"relative", left:"5px", bottom:"3px"}}>Radar function</span></DialogTitle>
            <DialogContent>
                <Typography style={{fontSize:"12px", marginBottom:"15px"}}>The radar function allows you to determine by the control word which smartcard decrypt the stream.</Typography>
                <Alert severity="warning">Within one crypto period (usually 10 seconds), a radar response signal will be sent from the smartcard.</Alert>
                
                {preloader ?
                        <div className="loader-center" style={{marginTop: "20px"}}><CircularProgress /></div>:
                        <div style={{margin:"30px 100px 0"}}>
                        <Typography>
                            <FormControlLabel
                            label=" strict database search"
                            control={<Checkbox
                                checked={strict}
                                onClick={(e)=>  setStrict(e.target.checked) }
                                />}
                            />
                        </Typography>
                        <TextField
                            //variant="filled"
                            label="Paste CW1 or CW2"
                            size="small"
                            value={cw}
                            onChange={(e) => {
                                let s = e.target.value
                                if (s.length === 16){
                                    setSerial("search..") 
                                    setCw(s)
                                    sendDataToServer({
                                        op: "radar_check",
                                        cw: s,
                                        mode: mode,
                                        strict: strict ? "1" : "0"
                                    }).then(function(r){
                                        if(r.status === "OK"){
                                            setSerial(r.serial) 
                                        }
                                    })
                                }
                            }}
                         />
                         <p style={{color:"#333"}}><b>Serial: </b>{serial !== "not found" ? <CopyToClipboard>{serial}</CopyToClipboard> : serial}</p>
                         </div>
                }
                </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => {
                    hideRadar(false)
                    setPreloader(true)
                    setSerial("not found")
                    setCw("")
                    setStrict(true)
                }}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}