import React, { useEffect, useState } from 'react'
import StorageIcon from '@mui/icons-material/Storage';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { sendDataToServer } from '../utils/functions';
import EcmG from '../components/ECMG';
import AlertNothing from '../components/AlertNothing';
import Cookies from 'js-cookie';

export default function EcmgPage(props){

    const [ecmg, setEcmg] = useState([])
    const [upd, setUpd] = useState(0)

    useEffect(() => {
        sendDataToServer({ 'op': "getEcmg" })
            .then(r => {
                setEcmg(r);
                //console.log(r)
            });
    }, [upd]);


    function handleAdd(){
        sendDataToServer({'op': "addEcmg"}).then(r => {
            setUpd(Math.random())
        })
    }



    return( 
        <>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h2>
                    <StorageIcon style={{ marginRight: "10px" }} />
                    ECM generators
                </h2>
                {Cookies.get("user") === "admin" ? 
                <span style={{marginTop:"20px"}}>
                    <Button variant="contained" onClick={handleAdd}><AddCircleOutlineIcon /> <span style={{paddingTop:"3px"}}>Add ECMg</span></Button>
                </span> : "" }
            </div>
            <hr />
            { 
                ecmg.length===0 ? (
                    <AlertNothing>No generators. Please add new ECMg.</AlertNothing>
                ) : (
                    ecmg.map(v => (
                        <EcmG key={"ecmg"+v.id} data={v} />
                    ))
                )
            }
        </>
    )
}