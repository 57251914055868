import React, { useEffect, useState } from "react";
import { Checkbox } from '@mui/material'
import { sendDataToServer } from "../utils/functions";



export default function CheckSmartcard(props){
    const [chk, setChk] = useState(props.value === "1")

    useEffect(() => {
        setChk(props.value === "1");
        //console.log([props.value])
    }, [props.value]);

    function handleChange(e){
        var v = e.target.checked
        sendDataToServer({
            op: "chkSmartcard",
            key: props.kkey,
            id: props.id,
            value: (v ? "1" : "0")
        }).then(r => {
            if (r.status === "OK"){
                setChk(v)
            }
        })
    }


    return (
        <Checkbox id={props.kkey+props.id} size="small" checked={chk} onChange={handleChange} />
    )
}