import React, { useEffect, useState } from 'react'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { sendDataToServer } from '../utils/functions';


export default function PagesSmartcard({onChange}){

    const [now, setNow] = useState(1)
    const [total, setTotal] = useState(1)
    const [lim, setLim] = useState(30)


    useEffect(function(){
        sendDataToServer({
            op: "getPages"
        }).then(r => {
            let l = parseInt(r.lim)
            setLim(l)
            setTotal(Math.ceil(parseInt(r.cnt["count(id)"]) / l))
        })
    },[])



    function pageMinus(){
        setNow(now -1)
        onChange((now-2) * lim)
    }

    function pagePlus(){
        setNow(now +1)
        onChange(now * lim)
    }




    return (
        <div style={{marginTop:"30px"}}>
            {
                total > 1 && <>
                    {
                        now > 1 && <ArrowLeftIcon className={"pagenation"} onClick={pageMinus} />
                    }
                    <span style={{position: "relative", bottom: "5px", padding: "0 5px"}}>{now + "/" + total}</span>
                    {
                        now < total && <ArrowRightIcon className={"pagenation"} onClick={pagePlus}  />
                    }
                </>
            }
        </div>
    )
}